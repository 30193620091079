module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1024,"linkImagesToOriginal":true,"loading":"lazy","showCaptions":true,"disableBgImage":true,"withWebp":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"decoding":"async","disableBgImageOnAlpha":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Data Show","language":"en","description":"Data Show makes data visualization notebooks with open data about economics and health topics.","canonical":"https://www.data-show.com/","openGraph":{"type":"website","locale":"en_US","url":"https://www.data-show.com/","description":"Data Show makes data visualization notebooks with open data about economics and health topics.","title":"Data Show","site_name":"Data Show"},"twitter":{"site":"DataShow_","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7JKYHNXE4G"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Data Show","short_name":"Data Show","start_url":"/","background_color":"#ffffff","theme_color":"#433e85","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a6763bd4e4586237f7df07a1bd60481b"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
